import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import WeightChart from "./WeightChart";
import {
  generateWTPaceButtons,
  WT_CHART_TIMELINE_BUTTONS,
  WT_GOAL_BUTTERFLY_BUTTONS,
  WT_LOG_BUTTERFLY_BUTTONS,
} from "../../../Supports/Buttons";
import Card from "../../Structural/Card/Card";
import CardContent from "../../Structural/Card/CardContent";
import {
  ARROW_SYM,
  INFO_BANNER_MAX_STRING,
  KILOGRAMS,
  POUNDS,
  STONES,
  WEIGHT_MAX,
  WEIGHT_MIN,
} from "../../../Supports/Constants";
import {
  converterUserWeight,
  getBMR,
  getTodayDate,
  isLeapYearUsingDate,
  presentUserWeights,
  shortenText,
  updateVal,
} from "../../../Supports/Functions";
import {
  generatePaceGoal,
  generateWeightGoal,
  generateWeightLogItem,
  generateWeightPrediction,
} from "../../../Supports/Templates";
import { deleteDoc, doc, getFirestore, setDoc } from "firebase/firestore";
import { DataContext } from "../../../Supports/Contexts";

export default function WeightCard(props) {
  const { t } = useTranslation();
  const Data = useContext(DataContext);

  const [weightLog, setWeightLog] = useState(null);
  const [calorieLog, setCalorieLog] = useState(null);

  const [weightTimeline, setWeightTimeline] = useState("2W");
  const today = props.activeDate;
  const userData = props.userData;

  const body = userData?.body;
  const weight = userData?.weight;
  const age = body?.age?.value;
  const height = body?.height;
  const sex = body?.sex;

  const [currentWeight, setCurrentWeight] = useState(null);

  const lastUserEntry = weight?.settings?.lastUserEntry || null;
  const weightUnitSetting = weight.settings.unit;

  const generateStub = useCallback(() => {
    if (!lastUserEntry) {
      return;
    }
    let latest;
    try {
      latest =
        weightLog[
          `${lastUserEntry.year}-${lastUserEntry.month}-${lastUserEntry.day}`
        ];
    } catch (e) {}

    if (latest) {
      const freshGoal = generateWeightGoal(
        latest.goal.value,
        latest.pace.value === 0
          ? "nochange"
          : latest.pace.value < 0
          ? "less"
          : "more",
        weightUnitSetting
      );

      const prediction = generateWeightPrediction(
        body.basalMetabolicRate,
        latest?.caloriesOut || body.basalMetabolicRate,
        latest?.caloriesIn || body.basalMetabolicRate,
        latest.value,
        freshGoal,
        latest.pace,
        latest.unit,
        today
      );

      const generated = generateWeightLogItem(
        latest.value,
        "stub",
        latest?.unit || weight.settings.unit,
        today,
        prediction,
        freshGoal,
        latest.pace
      );

      setCurrentWeight(generated);

      return setDoc(
        doc(
          getFirestore(),
          `UserData/${userData.id}/WeightLog`,
          `${today.year}-${today.month}-${today.day}`
        ),
        generated
      );
    }
  }, [
    lastUserEntry,
    weightLog,
    weight.settings.unit,
    weightUnitSetting,
    body.basalMetabolicRate,
    today,
    userData.id,
  ]);

  useEffect(() => {
    if (!Data.loadingCalorieLog) {
      setCalorieLog(Data.calorieLog);
    }
  }, [Data.calorieLog, Data.loadingCalorieLog]);

  useEffect(() => {
    !Data.loadingWeightLog && setWeightLog(Data.weightLog);
    if (
      Data.weightLog &&
      Data.weightLog[`${today.year}-${today.month}-${today.day}`]
    ) {
      setCurrentWeight(
        Data.weightLog[`${today.year}-${today.month}-${today.day}`]
      );
    } else {
      generateStub(); // FUTURE: Confirm this triggers on fresh days that aren't the first date.
    }
  }, [
    Data,
    Data.loadingWeightLog,
    Data.weightLog,
    generateStub,
    today.date,
    today.day,
    today.month,
    today.year,
  ]);

  // Cleanup datapoints beyond 3 year boundry.
  useEffect(() => {
    const todayObj = getTodayDate();
    const lastYear = todayObj.year - 3; // 3 year bound
    const month = todayObj.month;
    const day = todayObj.day;

    let lastYearEntryExists;

    try {
      lastYearEntryExists =
        !Data.loadingWeightLog &&
        weightLog[`${todayObj.year}-${todayObj.month}-${todayObj.day}`];
    } catch (e) {}

    if (lastYearEntryExists) {
      deleteDoc(
        doc(
          getFirestore(),
          `UserData/${userData.id}/WeightLog`,
          `${lastYear}-${month}-${day}`
        )
      );
    }

    const lastYearEntryExistsLeapYear =
      month === 3 &&
      day === 1 &&
      weightLog &&
      weightLog[`${lastYear}-${2}-${29}`] &&
      isLeapYearUsingDate(lastYear);

    if (lastYearEntryExistsLeapYear) {
      deleteDoc(
        doc(
          getFirestore(),
          `UserData/${userData.id}/WeightLog`,
          `${lastYear}-${2}-${29}`
        )
      );
    }
  }, [Data.loadingWeightLog, userData.id, weightLog]);

  if (!userData || !userData?.body?.age) {
    return <></>;
  }

  const genWeightInfo = () => {
    try {
      const goalCalc = converterUserWeight(
        currentWeight.goal.unit,
        currentWeight.goal.value,
        weightUnitSetting,
        true,
        1
      );

      const weightCalc = converterUserWeight(
        currentWeight.unit,
        currentWeight.value,
        weightUnitSetting,
        true,
        1
      );

      const goalDelta = (goalCalc - weightCalc).toFixed(1);

      const display = `${weightCalc} ${ARROW_SYM} ${goalCalc.toFixed(1)}${
        presentUserWeights(weightUnitSetting).short
      } | ${goalDelta}${
        presentUserWeights(weightUnitSetting).short
      } ${shortenText(t("left"), 10)}`;

      return shortenText(display, INFO_BANNER_MAX_STRING);
    } catch (e) {
      return "...";
    }
  };

  const goalPace = currentWeight?.pace;
  const weightPrediction = currentWeight?.prediction;
  const isGoal = goalPace?.value !== 0;
  const weightInfo = genWeightInfo();

  const stub = (
    <Card
      name="weight"
      id="weight"
      header={t("ct-wtt")}
      info={weightInfo}
      leftButtonTitle={t("cb-wtt-l")}
      rightButtonTitle={t("cb-wtt-r")}
      setActiveCardStates={props.setActiveCardStates}
      activeCardStates={props.activeCardStates}
    />
  );

  if (
    Data.loadingWeightLog ||
    Data.loadingCalorieLog ||
    !lastUserEntry ||
    !Data.weightLog
  ) {
    return stub;
  }

  const genLastWeightInfo = () => {
    if (currentWeight?.value) {
      return (
        t("c-wtt-l-ctx-body", {
          varWeight:
            converterUserWeight(
              currentWeight.unit,
              currentWeight.value,
              weightUnitSetting,
              true,
              1
            ) + presentUserWeights(weightUnitSetting).short,
        }) +
        " " +
        currentWeight.date
      );
    }
    return t("c-wtt-l-no-prior");
  };

  const lastWeightInfo = genLastWeightInfo();

  WT_LOG_BUTTERFLY_BUTTONS.selector = currentWeight
    ? converterUserWeight(
        currentWeight.unit,
        currentWeight.value,
        weightUnitSetting,
        true,
        1
      )
    : 0;

  WT_GOAL_BUTTERFLY_BUTTONS.selector = currentWeight?.goal
    ? converterUserWeight(
        currentWeight.goal.unit,
        currentWeight.goal.value,
        weightUnitSetting,
        true,
        1
      )
    : 0;

  WT_GOAL_BUTTERFLY_BUTTONS.step =
    weightUnitSetting === STONES
      ? 0.5
      : weightUnitSetting === KILOGRAMS
      ? 2
      : 5;

  WT_LOG_BUTTERFLY_BUTTONS.step =
    weightUnitSetting === STONES
      ? 0.1
      : weightUnitSetting === KILOGRAMS
      ? 0.25
      : 0.5;

  const WT_PACE_BUTTONS = generateWTPaceButtons(t("week").substring(0, 1));

  WT_PACE_BUTTONS.step =
    weightUnitSetting === STONES
      ? 0.1
      : weightUnitSetting === KILOGRAMS
      ? 0.25
      : 0.5;

  WT_LOG_BUTTERFLY_BUTTONS.buttons.center[0].modalSettings.unitType =
    weightUnitSetting;
  WT_LOG_BUTTERFLY_BUTTONS.buttons.center[0].modalSettings.minVal =
    converterUserWeight(POUNDS, WEIGHT_MIN, weightUnitSetting, true, 1);
  WT_LOG_BUTTERFLY_BUTTONS.buttons.center[0].modalSettings.maxVal =
    converterUserWeight(POUNDS, WEIGHT_MAX, weightUnitSetting, true, 1);

  WT_GOAL_BUTTERFLY_BUTTONS.buttons.center[0].modalSettings.unitType =
    weightUnitSetting;
  WT_GOAL_BUTTERFLY_BUTTONS.buttons.center[0].modalSettings.minVal =
    converterUserWeight(POUNDS, WEIGHT_MIN, weightUnitSetting, true, 1);
  WT_GOAL_BUTTERFLY_BUTTONS.buttons.center[0].modalSettings.maxVal =
    converterUserWeight(POUNDS, WEIGHT_MAX, weightUnitSetting, true, 1);

  const setWeight = (val) => {
    const today = getTodayDate();
    const current = generateWeightLogItem(
      val,
      "user",
      weightUnitSetting,
      today,
      weightPrediction,
      currentWeight.goal,
      goalPace
    );

    // NOTE: change to current.date vs. current.datetime would index based on millisecond vs. only one per date.
    // weightLog[today.year][today.month][today.day] = current;
    setCurrentWeight(current);

    const newBMR = getBMR({
      age: age,
      height: height,
      sex: sex,
      weight: converterUserWeight(currentWeight.unit, val, POUNDS),
    });

    const newSettings = {
      ...weight.settings,
      ...{
        lastUserEntry: {
          year: today.year,
          month: today.month,
          day: today.day,
        },
      },
    };

    weightLog[`${today.year}-${today.month}-${today.day}`] = current;

    updateVal({ "body.basalMetabolicRate": newBMR }, userData.id)
      .then(() =>
        setDoc(
          doc(
            getFirestore(),
            `UserData/${userData.id}/WeightLog`,
            `${today.year}-${today.month}-${today.day}`
          ),
          current
        )
      )
      .then(updateVal({ [`weight.settings`]: newSettings }, userData.id))
      .then(() => {
        props.setActiveCardStates({
          ...props.activeCardStates,
          ...{ weight: 1 },
        });
      });
  };

  const generateNewGoalAndPace = (source, val) => {
    let changePace = "nochange";
    let changeWeight = "nochange";

    const convertedCurrent = converterUserWeight(
      currentWeight.unit,
      currentWeight.value,
      weightUnitSetting,
      true,
      1
    );

    let currentWeightObj = currentWeight;

    if (source === "goal") {
      if (convertedCurrent > val && goalPace.value >= 0) {
        // New weight goal is less then current weight, yet the pace goal is either 0 or more.
        changePace = "negative"; // Thus, change the pace to be a negative direction.
      } else if (convertedCurrent < val && goalPace.value <= 0) {
        // New weight goal is more then current weight, yet the pace goal is either 0 or less.
        changePace = "positive"; // Thus, change the pace to be a positive direction.
      } else if (convertedCurrent === val && goalPace.value !== 0) {
        // New weight same as current weight, yet the pace goal is not zero'ed out.
        setWeightTimeline("2W"); // pushes out the main chart from goal view.
        changePace = "zero"; // Thus, change the pace to be zero'ed out.
      }

      const newGoal = generateWeightGoal(
        val,
        changePace === "zero"
          ? "nochange"
          : changePace === "negative"
          ? "less"
          : "more",
        weightUnitSetting
      );

      currentWeightObj = { ...currentWeightObj, goal: newGoal };

      if (changePace !== "nochange") {
        const step =
          weightUnitSetting === STONES
            ? 0.1
            : weightUnitSetting === KILOGRAMS
            ? 0.25
            : 0.5;
        const newPaceVal =
          changePace === "positive"
            ? step
            : changePace === "negative"
            ? -step
            : 0;

        const newPace = generatePaceGoal(newPaceVal, weightUnitSetting);
        const newGoalDirection =
          newPaceVal === 0 ? "nochange" : newPaceVal < 0 ? "less" : "more";

        currentWeightObj = {
          ...currentWeightObj,
          pace: newPace,
        };

        currentWeightObj.goal.direction = newGoalDirection;
      }
    } else {
      // pace first
      const convertedCurrent = converterUserWeight(
        currentWeight.unit,
        currentWeight.value,
        weightUnitSetting,
        true,
        1
      );

      const convertedGoal = converterUserWeight(
        currentWeight.goal.unit,
        currentWeight.goal.value,
        weightUnitSetting,
        true,
        1
      );

      if (val > 0 && convertedGoal <= convertedCurrent) {
        // new pace goal is positive yet the current goal weight is less then current weight.
        changeWeight = "positive"; // Thus, change the goal weight to be a positive direction.
      } else if (val < 0 && convertedGoal >= convertedCurrent) {
        // new pace goal is goal is negative yet the current goal weight is more then current weight.
        changeWeight = "negative"; // Thus, change the goal weight to be a negative direction.
      } else if (val === 0 && convertedGoal !== convertedCurrent) {
        setWeightTimeline("2W"); // pushes out the main chart from goal view.
        // New pace is zero, yet the goal weight is not same as current weight.
        changeWeight = "zero"; // Thus, change the goal weight to be the same as current weight.
      }

      const newPace = generatePaceGoal(val, weightUnitSetting);
      const newGoalDirection =
        val === 0 ? "nochange" : val < 0 ? "less" : "more";

      currentWeightObj = {
        ...currentWeight,
        pace: newPace,
        goal: { ...currentWeight.goal, direction: newGoalDirection },
      };

      if (changeWeight !== "nochange") {
        const step =
          weightUnitSetting === STONES
            ? 0.5
            : weightUnitSetting === KILOGRAMS
            ? 2
            : 5;
        const convertedCurrent = converterUserWeight(
          currentWeight.unit,
          currentWeight.value,
          weightUnitSetting,
          true,
          1
        );
        const newWeightGoal =
          changeWeight === "positive"
            ? convertedCurrent + step
            : changeWeight === "negative"
            ? convertedCurrent - step
            : currentWeight.value;

        const newGoal = generateWeightGoal(
          newWeightGoal,
          changePace === "zero"
            ? "nochange"
            : changePace === "negative"
            ? "less"
            : "more",
          weightUnitSetting
        );

        currentWeightObj = { ...currentWeightObj, goal: newGoal };
      }
    }

    return currentWeightObj;
  };

  const setWeightGoal = async (val) => {
    const convertedVal = converterUserWeight(weightUnitSetting, val, POUNDS);

    if (convertedVal < WEIGHT_MIN || convertedVal > WEIGHT_MAX) {
      return;
    }

    const newWeight = generateNewGoalAndPace("goal", val);
    const today = getTodayDate();
    weightLog[`${today.year}-${today.month}-${today.day}`] = newWeight;
    setCurrentWeight(newWeight);

    await setDoc(
      doc(
        getFirestore(),
        `UserData/${userData.id}/WeightLog`,
        `${today.year}-${today.month}-${today.day}`
      ),
      newWeight
    );

    return Data.setSyncWeightLog(true);
  };

  const setWeightPace = async (val) => {
    const newWeight = generateNewGoalAndPace("pace", val);
    const today = getTodayDate();
    weightLog[`${today.year}-${today.month}-${today.day}`] = newWeight;
    setCurrentWeight(newWeight);

    await setDoc(
      doc(
        getFirestore(),
        `UserData/${userData.id}/WeightLog`,
        `${today.year}-${today.month}-${today.day}`
      ),
      newWeight
    );

    return Data.setSyncWeightLog(true);
  };

  const visType = (internalProps) => {
    return <WeightChart visualizationData={internalProps.visualizationData} />;
  };

  const openCustomWeight = (params) => {
    props.setModalVisible("CUSTOM_WEIGHT_LOG");
  };

  return (
    <Card
      name="weight"
      id="weight"
      header={t("ct-wtt")}
      info={weightInfo}
      leftButtonTitle={t("cb-wtt-l")}
      rightButtonTitle={t("cb-wtt-r")}
      setActiveCardStates={props.setActiveCardStates}
      activeCardStates={props.activeCardStates}
      externalTriggers={(params) => {
        switch (params.trigger) {
          case "ADD_WEIGHT_LOG":
            openCustomWeight();
            break;
          default:
            break;
        }
      }}
    >
      <CardContent
        headerTitle={t("log")}
        contextTitle={t("c-wtt-l-ctx-head")}
        isVisualizationActive={false}
        isContextActive={true}
        contextInfo={lastWeightInfo}
        interactionTitle={t("c-wtt-l-int-head")}
        interactions={WT_LOG_BUTTERFLY_BUTTONS}
        interactionFunc={(btn) => setWeight(btn.value)}
        customInteractionFuncs={{
          CUSTOM_WEIGHT_LOG: (v) => setWeight(v),
        }}
        setModalVisible={props.setModalVisible}
        modalVisible={props.modalVisible}
      />
      <CardContent
        headerTitle={t("c-ftt-m-head")}
        contextTitle={t("c-ftt-r-head")}
        isVisualizationActive={true}
        visualizationType={visType}
        visualizationData={{
          weightLog: weightLog,
          range: weightTimeline,
          goalWeight: currentWeight?.goal || 0,
          goalPace: goalPace,
          isGoal: isGoal,
          weightUnitSetting: weightUnitSetting,
          calorieLog: calorieLog,
          isDarkTheme: props.isDarkTheme,
        }}
        weightTimeline={weightTimeline}
        isGoal={isGoal}
        isContextActive={false}
        interactionTitle={`${t("c-wtt-m-int")}: ${weightTimeline}${
          weightTimeline !== "GOAL"
            ? ""
            : ` (${goalPace.value} ${goalPace.unit} / ${t("week").substring(
                0,
                1
              )})`
        }`}
        interactions={WT_CHART_TIMELINE_BUTTONS}
        interactionFunc={(btn) => setWeightTimeline(btn.btnName)}
      />
      <CardContent
        headerTitle={t("c-wtt-m-title")}
        isContextActive={true}
        contextTitle={t("c-wtt-r-ctx-head", {
          varGoal:
            (goalPace
              ? converterUserWeight(
                  goalPace.unit,
                  goalPace.value,
                  weightUnitSetting,
                  true
                )
              : 0) + presentUserWeights(weightUnitSetting).short,
        })}
        contextInteractions={WT_PACE_BUTTONS}
        customContextFuncs={(btn) => setWeightPace(btn.value)}
        goalPace={goalPace}
        paceGoalValue={goalPace?.value || 0}
        isVisualizationActive={false}
        setModalVisible={props.setModalVisible}
        modalVisible={props.modalVisible}
        interactionTitle={
          currentWeight?.goal?.value
            ? t("c-wtt-r-ctx-body", {
                varGoal:
                  converterUserWeight(
                    currentWeight.goal.unit,
                    currentWeight.goal.value,
                    weightUnitSetting,
                    true,
                    1
                  ).toFixed(1) + presentUserWeights(weightUnitSetting).short,
              })
            : t("c-wtt-set-goal")
        }
        interactions={WT_GOAL_BUTTERFLY_BUTTONS}
        interactionFunc={(btn) => setWeightGoal(btn.value)}
        customInteractionFuncs={{
          CUSTOM_WEIGHT_GOAL: (v) => setWeightGoal(v),
        }}
      />
    </Card>
  );
}
